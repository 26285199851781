import SplashLayout from "@/components/layout/splashPage/SplashLayout";
import Team from "@/components/splashPage/team/Team";

const TeamPage = () => {
  return (
    <SplashLayout>
      <Team />
    </SplashLayout>
  );
};

export default TeamPage;
