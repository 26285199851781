const defaultTermData = [
  {
    tIndex: -1,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 1,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 2,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 3,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 5,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 6,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 7,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 9,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 10,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 11,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 13,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 14,
    tUnits: "0",
    courses: [],
  },
  {
    tIndex: 15,
    tUnits: "0",
    courses: [],
  },
];

export default defaultTermData;
